<template>
  <section class="section welcome-section for-customer">
    <div class="container">
      <div class="columns">
        <div class="column is-9-desktop">
          <h1 class="title mb-0" v-html="t(welcomeContent.title[0])"></h1>
          <p class="title" v-if="welcomeContent.title[1]">{{ t(welcomeContent.title[1]) }}</p>
          <p class="subtitle column is-10-desktop" style="margin-top: 1.5rem" v-html="t(welcomeContent.description)"></p>
          <Button :tag="NuxtLink" :to="buttonLink">{{ t(welcomeContent.btnText) }}</Button>
        </div>
        <figure class="illustration">
          <slot name="img" />
        </figure>
      </div>

    </div>
  </section>
</template>

<script lang="ts" setup>
import { useLocalePath } from "#i18n"
import { NuxtLink } from "#components"
import Button from "../Button.vue"

const localePath = useLocalePath();
const { t } = useI18n();

const props = defineProps({
  buttonLink: {
    type: String,
    required: true,
  },
  welcomeContent: Object
})
</script>

<style scoped></style>
