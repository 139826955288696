<template>
    <div class="advantage-item">
        <div class="advantage-icon">
            <slot name="advantage-icon" />
        </div>
            <p class="header" v-html="t(advantage.title)"></p>
            <div class="description">
                <template v-if="Array.isArray(advantage.descriptionItems)">
                    <p v-for="(desc, index) in advantage.descriptionItems" :key="index">— {{ t(desc) }}</p>
                </template>
                <p v-else>{{ t(advantage.descriptionItems) }}</p>
            </div>
     </div>
</template>
<script setup>
const { t } = useI18n();
const { advantage } = defineProps({
    advantage: Object
})

</script>
